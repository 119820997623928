<template>
  <div class="teacher-detail">
    <el-card>
      <!-- <div slot="header" class="clearfix">
        <span class="title">基本信息</span>
        <span style="float: right; padding: 3px 0;font-size:18px;color:#ff7b33"
          >授课报价:{{ detail.lowestOffer }}~{{ detail.highestOffer }}</span
        >
      </div> -->
      <div class="teacherBaseInfo">
        <div class="headerTitle">
          <div class="headerText">基本信息</div>
          <div class="price">授课报价:{{ detail.lowestOffer }}~{{ detail.highestOffer }}</div>
        </div>
        <el-row :gutter="20">
          <el-col :span="3">
            <div class="teacherInfo">
              <div class="teacherInfoImg"><img :src="teacherImg" alt="" /></div>
              <div class="name">{{ detail.teacherName }}</div>
              <div class="email">{{ detail.email }}</div>
            </div>
          </el-col>
          <el-col :span="21">
            <div class="base-info">
              <div class="info-item">
                <div class="label">性别：</div>
                <div class="value">{{ ['男', '女', '保密'][detail.gender - 1] }}</div>
              </div>
              <div class="info-item">
                <div class="label">邮箱：</div>
                <div class="value">{{ detail.email }}</div>
              </div>
              <div class="info-item">
                <div class="label">最高学历学校名称：</div>
                <div class="value">{{ detail.college }}</div>
              </div>
              <div class="info-item">
                <div class="label">出生日期：</div>
                <div class="value">{{ detail.birthday }}</div>
              </div>
              <div class="info-item">
                <div class="label">最高学历：</div>
                <div class="value">{{ detail.teacherQualificationStr }}</div>
              </div>
              <div class="info-item">
                <div class="label">职称：</div>
                <div class="value">{{ detail.teacherRankStr }}</div>
              </div>
              <div class="info-item">
                <div class="label">常驻地区：</div>
                <div class="value">{{ detail.province + detail.city }}</div>
              </div>
              <div class="info-item">
                <div class="label">毕业院校：</div>
                <div class="value">{{ detail.college }}</div>
              </div>
              <div class="info-item">
                <div class="label">讲师称号：</div>
                <div class="value">{{ detail.title }}</div>
              </div>
              <div class="info-item">
                <div class="label">讲师性质：</div>
                <div class="value">{{ detail.teacherSourceStr }}</div>
              </div>
              <div class="info-item">
                <div class="label">讲师分类：</div>
                <div v-if="detail.teacherMajorList" class="value">
                  {{ detail.teacherMajorList.map(e => e.name).join(',') }}
                </div>
              </div>
              <div class="info-item">
                <div class="label">学术头衔：</div>
                <div class="value">{{ detail.titles }}</div>
              </div>
              <div class="info-item">
                <div class="label">是否对外开放：</div>
                <div class="value">{{ { PUBLIC: '是', PRIVATE: '否' }[detail.accessLevel] }}</div>
              </div>
              <div class="info-item">
                <div class="label">研究所：</div>
                <div class="value">{{ detail.institutes }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="otherWrap">
          <div class="info-item">
            <div class="label">专长标签：</div>
            <div class="value">
              <div v-for="(item, index) in detail.teacherTagList" :key="index" class="value-item">
                {{ item.tagName }}
              </div>
            </div>
          </div>
          <div class="info-item">
            <div class="label">讲师特点：</div>
            <div class="value pre-line">{{ detail.feature }}</div>
          </div>
          <div class="info-item">
            <div class="label">讲师简历简述：</div>
            <div class="value">{{ filterHTMLTag(detail.teacherDetails) }}</div>
          </div>
          <div class="info-item">
            <div class="label">课程包：</div>
            <div
              v-if="detail.coursePackageUris && detail.coursePackageUris.length !== 0"
              class="value "
            >
              <img src="../../../assets/teacher/yasuo.png" alt="" class="compress" />
              <span class="text" @click="downloadCourse">下载</span>
            </div>
          </div>
        </div>
      </div>
      <div class="teacherOtherInfo">
        <div class="headerTitle">
          <div class="headerText">荣誉成果</div>
        </div>
        <div class="honor">
          <div class="info-item">
            <div class="label">认证证书：</div>
            <div class="value pre-line">{{ otherInfo.credentials }}</div>
          </div>
          <div class="info-item">
            <div class="label">线下课程：</div>
            <div class="value pre-line">{{ otherInfo.offlineCourses }}</div>
          </div>
          <div class="info-item">
            <div class="label">主持项目：</div>
            <div class="value pre-line">{{ otherInfo.projects }}</div>
          </div>
          <div class="info-item">
            <div class="label">出版作品：</div>
            <div class="value pre-line">{{ otherInfo.publications }}</div>
          </div>
          <div class="info-item">
            <div class="label">服务企业：</div>
            <div class="value pre-line">{{ otherInfo.serviceEnterprise }}</div>
          </div>
          <div class="videoWrap">
            <div class="label">授课视频：</div>
            <div class="value">
              <div
                v-for="item in list"
                :key="item.teachingVideoId"
                class="videoItem"
                @click="lookVideo(item)"
              >
                <img :src="item.videoPic" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video">
        <div class="headerTitle">
          <div class="headerText">线下课程</div>
        </div>
        <table-list
          class="tableList"
          :loading="loading"
          :data="offlineCoursesList"
          :columns="columns(this)"
        ></table-list>
      </div>
    </el-card>
    <LookVideo ref="LookVideo"></LookVideo>
  </div>
</template>
<script>
import TableList from '@/components/TableList'
import LookVideo from './components/LookVideo.vue'
import { teacherDetail, lectureVideoList, offlineCourses } from '@/api/teacher'
import to from 'await-to'
// 表格列配置
const columns = () => [
  {
    prop: 'coursePic',
    label: '课程封面',
    render: (h, { row }) => {
      return [
        row.coursePic
          ? h('img', {
              domProps: { src: row.coursePic },
              style: 'height:80px;width:80px;',
            })
          : null,
      ]
    },
  },
  {
    prop: 'courseName',
    label: '课程名称',
  },
  // {
  //   prop: 'courseIntroduction',
  //   label: '课程介绍',
  //   width: 500,
  //   render(h, { row }) {
  //     return h('div', {
  //       domProps: { innerHTML: _this.filterHTMLTag(row.courseIntroduction) },
  //       class: 'courseIntroduction',
  //     })
  //   },
  // },
  {
    prop: 'courseDuration',
    label: '课程时长',
    formatter: row => {
      return row.courseDuration ? row.courseDuration + '天' : ''
    },
  },
  {
    prop: 'teacherName',
    label: '任课老师',
  },
  // {
  //   label: '可用状态',
  //   render: (h, { row }) => {
  //     return [
  //       h('el-switch', {
  //         props: { value: row.status, activeValue: true, inactiveValue: false },
  //         on: {
  //           change: async v => {
  //             await _this.handleOpen(v, row)
  //           },
  //         },
  //       }),
  //     ]
  //   },
  // },
  {
    prop: 'createTime',
    label: '创建时间',
  },
]
export default {
  name: 'TeacherDetail',
  components: {
    LookVideo,
    TableList,
  },
  data() {
    return {
      detail: {},
      otherInfo: {},
      teacherImg: '',
      list: [],
      loading: false,
      columns,
      offlineCoursesList: [],
      pager: {
        total: 0,
        size: 10000,
        current: 1,
        teacherId: this.$route.params.teacherId,
      },
    }
  },
  mounted() {
    this.getDetail()
    this.lectureVideoListData()
    this.offlineCoursesData()
  },
  methods: {
    async offlineCoursesData() {
      if (this.loading) return
      const { current, size, teacherId } = this.pager
      this.loading = true
      const [res, err] = await to(offlineCourses({ current, size, teacherId }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.offlineCoursesList = res.data.list
      this.pager.total = res.data.total
    },
    async getDetail() {
      const { teacherId } = this.$route.params
      const [res, err] = await to(teacherDetail({ userId: teacherId }))
      if (err) return this.$message.warning(err.msg)
      this.teacherData = res.data
      this.detail = res.data.teacherBaseInfo
      this.teacherImg = this.detail.teacherPicList[0]
      this.otherInfo = res.data.teacherOtherInfo
    },
    downloadCourse() {
      const href = this.detail.coursePackageUris[0].url
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.download = '讲师课程包'
      a.click()
      URL.revokeObjectURL(a.href)
    },
    async lectureVideoListData() {
      const { teacherId } = this.$route.params
      const [res, err] = await to(lectureVideoList({ teacherId, status: true }))
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.list
    },
    lookVideo(item) {
      this.$refs.LookVideo.show(item)
    },
    filterHTMLTag(content) {
      if (content !== '' && content !== undefined && content !== null) {
        var msg = content.replace(/<\/?[^>]*>/g, '')
        msg = msg.replace(/[|]*\n/, '') //去除行尾空格
        msg = msg.replace(/&nbsp;/gi, '') //去掉npsp
      } else {
        msg = ''
      }
      return msg
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-detail {
  .headerTitle {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    background: #fff5f0;
    margin-bottom: 20px;
    .headerText {
      color: #333333;
      font-size: 16px;
    }
    .price {
      font-size: 18px;
      color: #ff7b33;
    }
  }
  .teacherInfo {
    width: 128px;
    font-size: 14px;
    color: #333;
    text-align: center;
    .teacherInfoImg {
      width: 128px;
      height: 128px;
    }
    .name {
      font-size: 16px;
      margin-top: 10px;
    }
    .email {
      margin-top: 8px;
    }
  }
  min-height: calc(100vh - 140px);
  box-sizing: border-box;
  .base-info {
    display: flex;
    flex-wrap: wrap;
    .info-item {
      width: 33%;
      display: flex;
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
      .label {
        width: 126px;
        text-align: right;
        color: #909399;
        margin-right: 10px;
      }
      .value {
        flex: 1;
        overflow: hidden;
        @extend %one-line-text;
      }
    }
  }
  .otherWrap {
    width: 70%;
    margin-left: 13%;
    margin-top: 42px;
    .info-item {
      width: 70%;
      margin-bottom: 20px;
      display: flex;
      font-size: 14px;
      .label {
        width: 100px;
        text-align: right;
        color: #909399;
        margin-right: 10px;
      }
      .value {
        flex: 1;
        overflow: hidden;
        .value-item {
          display: inline-block;
          padding: 0 10px;
          height: 36px;
          line-height: 36px;
          background: #f2f2f2;
          border-radius: 4px;
          text-align: center;
          margin-right: 16px;
          margin-bottom: 10px;
        }
      }
      .compress {
        width: 39px;
        height: 38px;
      }
      .text {
        color: #ff7b33;
        font-size: 14px;
        margin-left: 11px;
        cursor: pointer;
      }
    }
  }
  .honor {
    margin-left: 13%;
    .info-item,
    .videoWrap {
      margin-bottom: 20px;
      display: flex;
      font-size: 14px;
      .label {
        width: 100px;
        text-align: right;
        color: #909399;
        margin-right: 10px;
      }
      .value {
        flex: 1;
        overflow: hidden;
        .videoItem {
          &:first-child {
            margin-left: 0;
          }
          float: left;
          margin-left: 28px;
          cursor: pointer;
          img {
            width: 224px;
            height: 126px;
          }
        }
        .value-item {
          display: inline-block;
          width: 100px;
          height: 36px;
          line-height: 36px;
          background: #f2f2f2;
          border-radius: 4px;
          text-align: center;
          margin-right: 16px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .video {
    .tableList {
      ::v-deep .el-table tr .courseIntroduction {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; //控制行数
        overflow: hidden;
      }
      margin-left: 13%;
      width: 70%;
    }
  }
  .pre-line {
    white-space: pre-line;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
