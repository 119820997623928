import config from './config/teacher'
import axios from '@/utils/request'
export const getTeacherList = params => axios.post(config.teacherList, params) //老师预约列表
export const getTeacherTypeSelect = params => axios.post(config.teacherTypeSelect, params) //老师分类下拉
export const getTeacherTagSelect = params => axios.post(config.teacherTagSelect, params) //老师专长下拉
export const teacherCreate = params => axios.post(config.teacherCreate, params) //新建老师
export const teacherUpdate = params => axios.post(config.teacherUpdate, params) //修改老师
export const teacherOpen = params => axios.post(config.teacherOpen, params) //设置老师是否公开
export const teacherDelete = params => axios.post(config.teacherDelete, params) // 批量删除
export const teacherDetail = params => axios.post(config.teacherDetail, params) // 讲师详情
export const teacherAccesslevel = params => axios.post(config.teacherAccesslevel, params) // 讲师是否公开，表格
export const orgTeacherList = params => axios.post(config.orgTeacherList, params) // 机构下老师
export const bingTeacher = params => axios.post(config.bingTeacher, params) // 绑定老师
export const searchTeacherPhone = params => axios.post(config.searchTeacherPhone, params) // 查询绑定老师手机号
export const offlineCourses = params => axios.post(config.offlineCourses, params) // 线下课程列表
export const ceratedOfflineCourses = params => axios.post(config.ceratedOfflineCourses, params) // 创建线下课程
export const searchOfflineCourses = params => axios.post(config.searchOfflineCourses, params) // 编辑查询
export const editOfflineCourses = params => axios.post(config.editOfflineCourses, params) // 编辑
export const changeStatus = params => axios.post(config.changeStatus, params) // 切换线下课程状态
export const deleteBatch = params => axios.post(config.deleteBatch, params) //批量删除线下课程
export const lectureVideoList = params => axios.post(config.lectureVideoList, params) //授课视频列表
export const videoChangeStatus = params => axios.post(config.videoChangeStatus, params) //切换授课视频状态
export const videoDeleteBatch = params => axios.post(config.videoDeleteBatch, params) //批量删除授课视频
export const addVideo = params => axios.post(config.addVideo, params) //创建授课视频
export const searchVideo = params => axios.post(config.searchVideo, params) //编辑查询授课视频
export const editVideo = params => axios.post(config.editVideo, params) //编辑授课视频
export const videoSearch = params => axios.post(config.videoSearch, params) //vid查视频
export const merchantTeacherFindMyOrder = params =>
  axios.post(config.merchantTeacherFindMyOrder, params) //我的订单
export const merchantTeacherFindMyOrderDetailExport = params =>
  axios.post(config.merchantTeacherFindMyOrderDetailExport, params, { responseType: 'blob' }) //我的订单导出
export const myCourseList = params => axios.post(config.myCourseList, params) //我的课程
export const myCourseRecord = params => axios.post(config.myCourseRecord, params) //销售记录
export const myCourseRecordExport = params =>
  axios.post(config.myCourseRecordExport, params, { responseType: 'blob' }) //销售记录导出
export const myCourseDetail = params => axios.post(config.myCourseDetail, params) //课程详情
export const myOrderDetail = params => axios.post(config.myOrderDetail, params) //订单详情
export const merchantTeacherIncomeAndPayFlow = params =>
  axios.post(config.merchantTeacherIncomeAndPayFlow, params) //收支明细
export const findTeacherOrderStatistic = params =>
  axios.post(config.findTeacherOrderStatistic, params) //销售统计
export const merchantTeacherIncomeAndPayFlowExport = params =>
  axios.post(config.merchantTeacherIncomeAndPayFlowExport, params, { responseType: 'blob' }) //收支明细导出
export const teacherMonthStatistic = params => axios.post(config.teacherMonthStatistic, params) //订单详情
export const teacherStatisticProductViews = params =>
  axios.post(config.teacherStatisticProductViews, params) //订单详情
export const myOrderDetailExport = params =>
  axios.post(config.myOrderDetailExport, params, { responseType: 'blob' }) //销售记录导出
