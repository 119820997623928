export default {
  teacherList: '/course/supply/teacher/query/list',
  teacherTypeSelect: '/course/manage/teacher/query/selectlist/teachermajor',
  teacherTagSelect: '/course/supply/teacher/get/tagtree/list',
  teacherCreate: '/course/supply/teacher/create',
  teacherUpdate: '/course/supply/teacher/edit',
  teacherOpen: '/course/supply/teacher/set/publicscope/batch',
  teacherDelete: '/course/supply/teacher/delete/batch',
  // teacherDetail: '/course/supply/teacher/query',
  teacherDetail: '/course/supply/teacher/queryByUserId',
  teacherAccesslevel: '/course/supply/teacher/change/accesslevel',
  orgTeacherList: '/course/supply/teacher/select/list',
  bingTeacher: '/course/supply/teacher/bind/account',
  searchTeacherPhone: '/course/supply/teacher/bind/account/phone/query',
  offlineCourses: '/course/supply/offline/course/query/page',
  ceratedOfflineCourses: '/course/supply/offline/course/create',
  searchOfflineCourses: '/course/supply/offline/course/query',
  editOfflineCourses: '/course/supply/offline/course/edit',
  changeStatus: '/course/supply/offline/course/change/status',
  deleteBatch: '/course/supply/offline/course/delete/batch',
  lectureVideoList: '/course/supply/teaching/video/query/page',
  videoChangeStatus: '/course/supply/teaching/video/change/status',
  videoDeleteBatch: '/course/supply/teaching/video/delete/batch',
  addVideo: '/course/supply/teaching/video/create',
  searchVideo: '/course/supply/teaching/video/query',
  editVideo: '/course/supply/teaching/video/edit',
  videoSearch: '/course/video/play/info',
  merchantTeacherFindMyOrder: '/order/merchantTeacherFindMyOrder',
  merchantTeacherFindMyOrderDetailExport: '/order/merchantTeacherFindMyOrderDetailExport',
  myCourseList: '/course/supply/teacher/my/goods',
  myCourseRecord: '/course/supply/teacher/goods/sale/record',
  myCourseRecordExport: '/course/supply/teacher/goods/sale/record/export',
  myCourseDetail: '/course/supply/teacher/good/detail',
  myOrderDetail: '/order/merchantTeacherFindMyOrderDetail',
  myOrderDetailExport: '/order/merchantTeacherFindMyOrderDetailExport',
  merchantTeacherIncomeAndPayFlow: '/order/merchantTeacherIncomeAndPayFlow',
  findTeacherOrderStatistic: '/order/findTeacherOrderStatistic',
  merchantTeacherIncomeAndPayFlowExport: '/order/merchantTeacherIncomeAndPayFlow/export',
  teacherMonthStatistic: '/order/teacherMonthStatistic',
  teacherStatisticProductViews: '/course/event/teacherStatisticProductViews',
}
