<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-09-10 17:05:36
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-10 17:49:43
-->
<template>
  <div>
    <el-dialog
      title="讲师视频"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleClose"
      class="aboutsNews"
    >
      <div id="prism-player" class="prism-player"></div>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { videoSearch } from '@/api/teacher'
export default {
  data() {
    return {
      dialogVisible: false,
      videoUrl: '',
      player: null,
    }
  },
  destroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    show(item) {
      this.dialogVisible = true
      this.$nextTick(() => {
        // eslint-disable-next-line no-undef
        this.player = new Aliplayer({
          id: 'prism-player',
          width: '100%',
          height: '500px',
          autoplay: true,
          isLive: false,
          rePlay: true,
          controlBarVisibility: 'hover',
        })
      })
      this.videoSearchData(item)
    },
    async videoSearchData(item) {
      const { videoId, videoPlatform } = item
      const [res, err] = await to(videoSearch({ videoId, videoPlatform }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.videoUrl = res.data.videoUrl
      if (this.player && this.videoUrl !== '') {
        this.player.loadByUrl(res.data.videoUrl)
        return
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.player.dispose()
    },
  },
}
</script>

<style scoped lang="scss"></style>
